import logo from '../logo.svg';

export function Home() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <ul>
        <li><a href="https://synology.itspektar.net">synology.itspektar.net</a></li>
      </ul>
    </header>
  );
}
