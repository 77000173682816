import React, { useState } from "react";
import jwtDecode from 'jwt-decode';
import AWS from "aws-sdk";
import { Button, Form, Alert } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export function getEmail(tokens) {
  const decodedIdToken = jwtDecode(tokens.IdToken);
  const email = decodedIdToken.email;
  return email;
}

export const refreshAccessToken = () => {
  return new Promise((resolve, reject) => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
      UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      AuthParameters: {
        REFRESH_TOKEN: tokens.RefreshToken,
      },
    };
    cognito.adminInitiateAuth(params, (err, data) => {
      if (err) {
        console.error(err);
        reject(false);
        // Handle the error when refreshing access token fails
      } else {
        // console.log('refreshAccessToken', data.AuthenticationResult);
        // Update the access token in state
        localStorage.setItem('tokens', JSON.stringify(data.AuthenticationResult))
        resolve(true);
      }
    });
  });    
};

export const Login = ({ setEmail }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  
  const handleSignIn = () => {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    const params = {
      AuthFlow: "ADMIN_NO_SRP_AUTH",
      ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
      UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    };
    cognito.adminInitiateAuth(params, (err, data) => {
      if (err) {
        console.error(err);
        // Handle the authentication error
        setError("Authentication failed. Please check your username and password.");
      } else {
        // console.log(data.AuthenticationResult);
        // Handle the authentication success
        localStorage.setItem('tokens', JSON.stringify(data.AuthenticationResult))
        // Schedule a token refresh when the access token is close to expiration
        setTimeout(refreshAccessToken, data.AuthenticationResult.ExpiresIn * 1000 * 0.9); // Refresh at 90% of the expiration time

        // Store the refresh timeout ID in state for later cleanup
        // console.log(data.AuthenticationResult.AccessToken);
        // Redirect to the home page
        setEmail(getEmail(data.AuthenticationResult));
        navigate('/');
      }
    });
  };  


  return (
    <div className="login-container">
      <h2>Login</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group controlId="username" className="form-group">
          <Form.Label>Username:</Form.Label>
          <Form.Control
            type="email"
            value={username}
            placeholder="name@example.com"
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="password" className="form-group">
          <Form.Label className="form-label">Password:</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="button" onClick={handleSignIn} className="button">
          Sign In
        </Button>
      </Form>
    </div>
  );
};
