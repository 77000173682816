import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { useState } from "react";
import jwtDecode from 'jwt-decode';
import { Home } from "./Home";
import Imprint from "./Imprint";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import NotFound from "./NotFound";
import { Login, getEmail, refreshAccessToken } from './Login';
import Logout from "./Logout";
import { useEffect } from "react";
import Layout from "./Layout";

export function Header() {
  const [email, setEmail] = useState("Sign In");
  const storageTokens = localStorage.getItem("tokens");
  const tokens = storageTokens ? JSON.parse(storageTokens) : false;

  useEffect(() => {
    // Check if user is authenticated (e.g., check tokens in local storage)
    if (tokens) {
      if (isTokenExpired(tokens.AccessToken)) {
        refreshAccessToken().then((success) => {
          console.log("Access token refreshed:", success);
          setEmail(getEmail(tokens));
        }).catch((error) => {
          // setEmail("Sign In");
          Logout({setEmail});
        });
      } else {
        setEmail(getEmail(tokens));
      }
    } else {
      // Clear the tokens from localStorage if expired or not found
      setEmail("Sign In");
    }
  }, [tokens]);

  const isTokenExpired = (token) => {
    if (!token) return true;

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

    return decodedToken.exp < currentTime;
  };

  const renderAuthDropdown = () => {
    if (tokens) {
      return (
        <NavDropdown title={email} id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/logout">
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else {
      // User is not logged in
      return (
        <NavDropdown title={email} id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/login">
            Sign In
          </NavDropdown.Item>
        </NavDropdown>
      );
    }
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand>IT Spektar</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/">Home</Nav.Link>
          </Nav>
          <Nav className="ms-auto">{renderAuthDropdown()}</Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route
            index
            element={tokens ? <Home /> : <Navigate to="/login" replace />}
          />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/login" element={<Login setEmail={setEmail} />} />
          <Route path="/logout" element={<Logout setEmail={setEmail} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};
